import { z } from 'zod'
import { useSharedData } from '../context/SharedDataProvider'

export const Role = z.enum([
  'admin',
  'company_person',
  'super_admin',
  'normal_admin',
])
export type UserRoles = z.infer<typeof Role>[]

export const getRoleText = (roles: string[]): string => {
  let roleName = ''
  if (roles.includes(Role.Enum.super_admin)) {
    roleName = 'スーパー管理者'
  } else if (roles.includes(Role.Enum.normal_admin)) {
    roleName = '一般管理者'
  }
  return roleName
}

export const hasRole = (
  roles: string[],
  role: z.infer<typeof Role>,
): boolean => {
  return roles.includes(role)
}

// custom hooks
export const useIsAdmin = (): boolean => {
  const { userRoles } = useSharedData()
  return hasRole(userRoles, Role.Enum.admin)
}

export const useIsSuperAdmin = (): boolean => {
  const { userRoles } = useSharedData()
  return hasRole(userRoles, Role.Enum.super_admin)
}

export const useIsCompanyPerson = (): boolean => {
  const { userRoles } = useSharedData()
  return hasRole(userRoles, Role.Enum.company_person)
}

export const useIsNormalAdmin = (): boolean => {
  const { userRoles } = useSharedData()
  return hasRole(userRoles, Role.Enum.normal_admin)
}